import { PATHS } from 'constants/paths';

export const PERKS = [
    {
        title: `services.perks.title1`,
        text: `services.perks.description1`,
    },
    {
        title: `services.perks.title2`,
        text: `services.perks.description2`,
    },
    {
        title: `services.perks.title3`,
        text: `services.perks.description3`,
    },
];

export const PERKS_LINKS = [
    {
        key: 'expertisesLink',
        url: PATHS.EXPERTISES,
        component: 'gatsby-link',
    },
    {
        key: 'bestPracticesLink',
        url: 'blog/javascript/best-practices-nodejs',
        component: 'gatsby-link',
    },
    {
        key: 'fintechLink',
        url: `${PATHS.EXPERTISES}/${PATHS.FINTECH}`,
        component: 'gatsby-link',
    },
    {
        key: 'proptechLink',
        url: `${PATHS.EXPERTISES}/${PATHS.PROP_TECH_REAL_ESTATE}`,
        component: 'gatsby-link',
    },
    {
        key: 'musicLink',
        url: `${PATHS.EXPERTISES}/${PATHS.AUDIO_VIDEO}`,
        component: 'gatsby-link',
    },
    {
        key: 'blockchainLink',
        url: `${PATHS.EXPERTISES}/${PATHS.NFT}`,
        component: 'gatsby-link',
    },
    {
        key: 'maintenanceLink',
        url: `blog/business/software-sla-agreement`,
        component: 'gatsby-link',
    },
    {
        key: 'timeMaterialsLink',
        url: 'blog/t-and-m-vs-fixed-price',
        component: 'gatsby-link',
    },
    {
        key: 'technologiesLink',
        url: PATHS.TECHNOLOGIES,
        component: 'gatsby-link',
    },
    {
        key: 'nextLink',
        url: `blog/nextjs-server-sider-rendering-guidline`,
        component: 'gatsby-link',
    },
    {
        key: 'gatsbyLink',
        url: `blog/gatsbyjs-the-ultimate-guideline`,
        component: 'gatsby-link',
    },
];

export const ADVANTAGES = [
    {
        index: 1,
        section: 'enhanced-automation-efficiency',
        paragraphsCount: 1,
        links: [
            {
                key: 'link',
                url: `${PATHS.BLOG}/ai-enhanced-software-development`,
                component: 'gatsby-link',
            },
        ],
    },
    {
        index: 2,
        section: 'personalized-user-experiences',
        paragraphsCount: 1,
        links: [
            {
                key: 'link',
                url: `${PATHS.CASE_STUDIES}/${PATHS.EXFLUENTIAL}`,
                component: 'gatsby-link',
            },
        ],
    },
    {
        index: 3,
        section: 'intelligent-data-analysis',
        paragraphsCount: 1,
        links: [
            {
                key: 'link',
                url: `${PATHS.CASE_STUDIES}/${PATHS.SALES_FORECASTING_SOFTWARE}`,
                component: 'gatsby-link',
            },
        ],
    },
    {
        index: 4,
        section: 'improved-natural-language-processing',
        paragraphsCount: 1,
    },
    {
        index: 5,
        section: 'scalable-solutions-growing-businesses',
        paragraphsCount: 1,
    },
];

export const ADVANTAGES_FOOTER_LINKS = [
    {
        key: 'link',
        url: PATHS.CONTACT_US,
        component: 'gatsby-link',
    },
];
